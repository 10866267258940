.pannel-navbar {
    padding: 0.25rem 2.5rem;
    border-radius: 0.25rem;
    background: var(--white, #FFF);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
}

.pannel-navbar-container {
    gap: 0.625rem;
    align-self: stretch;
    justify-content: space-between;

}

.pannel-navbar-right-section {
    width: 50%;
    justify-content: flex-end;

}

.notfication-icon {
    background: var(--gray);
    padding: 0.625rem;
    border-radius: 0.5rem;
    width: fit-content;

}

.user-profile {
    align-items: center;
}

.user-profile>img {
    width: 12%;
}

.user-profile>span {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}