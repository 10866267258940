.lead-management-heading {
  /* border: 1px solid black; */
  color: #1D3679;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.lead-management-tablehead {
  /* border: 1px solid red; */
  background: #1D3679;
}
.lead-management-tablehead-heading {
  /* width: 60%; */
  /* border: 1px solid blue; */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
}
.lead-management-tablebody {
  /* border: 1px solid red; */
  background: rgba(28, 63, 159, 0.05);
}
.lead-management-tablebody-heading {
  /* width: 60%; */
  /* border: 1px solid blue; */
 
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #5B5B5B;
}
.lead-management-tablebody:nth-of-type(even) {
  /* border: 1px solid red; */
  background: rgba(247, 147, 30, 0.05);
}
.terms-and-condition-button {
  padding: 13px 26px;
  border: 1px solid #1D3679;
  color: var(--text-color, #5B5B5B);
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}
.user-details{
  color: var(--text-color, #5B5B5B);
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px;
}

.hidden {
  display: none;
}