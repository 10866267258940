/** TODO varshit and kashif to be refactored start
**/

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* /!==================== VARIABLES CSS ====================/  */
:root {
  --blue: #1d3679;
  --blue-hover: #1c3f9f;
  --orange: #fd6202;
  --label: #5b5b5b;
  --input-border: #dddddd;
  --white: #fff;
  --gray: #f4f7ff;
  --stroke: #e7e7e7;
  --tail-grid-text: #637381;
  --scrollbar: #d9d9d9;
  /* /========== Font and typography ==========/  */
  --body-font: 'Roboto', sans-serif;

  /* /========== Font Size ==========/  */
  --text-55: 3.4375rem;
  --text-50: 3.125rem;
  --text-45: 2.813rem;
  --text-32: 2rem;
  --text-18: 1.125rem;
  --text-16: 1rem;
  --text-15: 0.9375rem;
  --text-14: 0.875rem;

  /* /========== Colors ==========/  */
  --info: #405fb2;
  --danger: #e5252a;
  --warn: #fd9902;
  --success: #009645;
}

/* /!==================== BASE ====================/  */
* {
  /* box-sizing: border-box; */
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  background-color: var(--body-color);
  /* color: var(--white); */
}

/* /!==================== Utility ====================/  */
.disable {
  cursor: default;
  pointer-events: none;
  user-select: none;
}

/* Catalogue - CSS */

.catalogue-details:nth-child(even) {
  background: rgba(28, 63, 159, 0.05);
}

.catalogue-details:nth-child(odd) {
  background: rgba(247, 147, 30, 0.05);
}
/* Highlight for selected row */
.record-selected {
  background: rgba(
    120,
    222,
    69,
    0.2
  ); /* Change to your desired highlight color */
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #d85b39;
}

input:focus + .slider {
  box-shadow: 0 0 1px #d85b39;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.danger {
  background-color: var(--danger);
}

.warning {
  background-color: var(--warn);
}

.success {
  background-color: var(--success);
}

.info {
  background-color: var(--info);
}

.danger-tab {
  border-radius: 50px;
  padding: 4px 12px;
  font-size: 12px;
  color: #ffffff;
  background-color: var(--danger);
}

.warning-tab {
  border-radius: 50px;
  padding: 4px 12px;
  font-size: 12px;
  color: #ffffff;
  background-color: var(--warn);
}

.success-tab {
  border-radius: 50px;
  padding: 4px 12px;
  font-size: 12px;
  color: #ffffff;
  background-color: var(--success);
}

.info-tab {
  border-radius: 50px;
  padding: 4px 12px;
  font-size: 12px;
  color: #ffffff;
  background-color: var(--info);
}
.scrollbar::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: var(--scrollbar);
  border-radius: 20px;
}

/* Graph */
.lineText .recharts-cartesian-axis-tick {
  transform: translateY(10px);
}

.recharts-cartesian-axis-tick-line {
  stroke: none;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar,
.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar,
.MuiMultiSectionDigitalClockSection-root {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* new tag css */
.new-tag {
  color: white;
  font-size: 12px;
  padding: 4px 16px;
  border-radius: 50px;
  margin-left: 15px;
  margin-right: 10px;
  background: linear-gradient(99deg, #1d3679 0.79%, #fd6202 100%);
}

/** TODO varshit and kashif to be refactored end
**/
