.search-upc-heading {
    border-bottom: 2px solid #dddddd;
    color: var(--text-color, #5b5b5b);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  
  .search-upc-container1 {
    background: var(--white, #fff);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
    /* height: 955px; */
  }
  .search-button {
    display: flex;
    padding: 13px 26px;
    justify-content: center;
    align-items: center;
    gap: 26px;
    border-radius: 4px;
    background: var(--blue, #1d3679);
  }
  .add-sku-button {
    border-radius: 4px;
    border: 1px solid var(--blue, #1d3679);
    background: var(--blue, #1d3679);
    color: #FFF;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    cursor: pointer;
  }
  .header {
    color: var(--blue-2, #1d3679);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .details {
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
  }
  .product-description {
    color: var(--text-color, #5b5b5b);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .properties-names {
    color: var(--text-color, #5b5b5b);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .properties-values {
    color: var(--text-color, #5b5b5b);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .input-details {
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
  }
  .mt-unit {
    border: 1px solid #d9d9d9;
    background: #d9d9d9;
  }
  .preview-draft1 {
    border-radius: 4px;
    border: 1px solid var(--blue, #1d3679);
    color: var(--text-color, #5b5b5b);
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    cursor: pointer;
  }
  
  .sku-background{
    /* border: 1px solid red; */
    /* width:1400px; */
    background-repeat: repeat-x;
    background-image: url('../../../Assets//Images//sku-background.png');
    /* background-repeat: no-repeat; */
  }