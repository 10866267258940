.otp-input-container {
    position: relative;
}

.otp-input {
    width: 3rem !important;
    height: 3rem !important;
}

.otp-input-container .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    text-align: center !important;
}

.otp-error {
    position: absolute;
    top: 100%;
}