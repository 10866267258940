.button {
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-transform: capitalize;

  cursor: pointer;

  display: flex;
  padding: 13px 26px !important;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.defaultButton {
   
  color: var(--text-color, #5b5b5b);
  background-color: #fff;
  border: 1px solid var(--blue, #1d3679);
}
.primaryButton {
  color: #fff;
  background: var(--blue, #1D3679);
}


.linkButton {
    color: var(--orange, #FD6202);
    background-color: #fff;
  border: none;
 
}
.secondaryButton {
  color:var(--blue, #1d3679);
  padding: 0px !important;
  background:  #fff;
}

/* .small {
  padding: 8px 0px;
  font-size: 14px;
  line-height: 1;
}
.disabled {
  opacity: 0.6;
} */
