/* ManufactureTableHeader css  */
.manufacture-header-wrapper h1 {
    font-size: 20px;
    color: #1D3679;
    font-weight: 600;
    margin-bottom: 12px;
}

.manufacture-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-btn {
    background-color: #1D3679 !important;
    color: #fff;
    text-transform: none;
    padding: 13px 26px !important;
    text-transform: none;
}

.manufacture-select {
    display: flex;
    gap: 20px;
}

.manufacture-btn {
    color: #5B5B5B;
    border-radius: 0;
    font-size: 16px;
    text-transform: none;
}

.manufacture-btn:hover {
    color: #1D3679;
    border-bottom: 1px solid #1D3679;
    background-color: transparent;
}

.table-row {
    background-color: #1D3679;
}

.table-row th {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.table-data tr td {
    font-size: 14px;
    color: #5B5B5B;
}

.table-data tr th {
    font-size: 14px;
    color: #5B5B5B;
}

.table-data tr:nth-child(even) {
    background: rgba(28, 63, 159, 0.05);
}

.table-data tr:nth-child(odd) {
    background: rgba(247, 147, 30, 0.05);
}

.table-icons {
    display: flex;
    gap: 5px;
    align-items: center;
}

/* .iocn {
    color: red !important;
} */
/* .MuiOutlinedInput-notchedOutline {
    border: none !important;
} */
/* #status-label>.MuiInputBase-formControl> .MuiOutlinedInput-notchedOutline {
    border: none;
} */
.search-box {
    border: 1px solid #DDD !important;
    border-radius: 8px;
    /* padding: 0px 12px; */
}

.MuiInputLabel-animated {
    background-color: #fff;
}

.dropdown-select {
    margin-left: -10px;
    align-items: center;
}


/* new manufactuer name css  */
.new-manufature-wrapper {
    padding: 24px;
    border: 1px solid gray;
}

.new-manufacture-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 1px solid #DDD;
}

.new-manufacture-header h3 {
    font-size: 24px;
    font-weight: 600;
    color: #5B5B5B;
}

.close-icon {
    font-size: 40px !important;
}

.manufacture-options {
    display: flex;
    gap: 5px;
    margin-bottom: 24px;

}

.options-header-btn:hover {
    border-bottom: 12px solid #1D3679 !important;
    background-color: transparent;
    color: #1D3679;
}

.options-header-btn {
    border-bottom: 12px solid #E4E4E4 !important;
    width: 33.33%;
    border-radius: 0 !important;
    color: #4D4D4D !important;
    font-size: 12px;
    text-transform: none;
}

/* .new-manufacture-data {
    display: flex;
    fle
} */

/* .new-data-main {
    width: calc(100% / 2);
} */

.select-options {
    width: 100%;
}

.data-content {
    display: flex;
    gap: 10px;
    margin: 18px 0px;
}

.select-type {
    width: 50%;
}

.data-name {
    width: 50%;
}


/* .data-wrapper {
    padding: 10px;
} */

.new-data-feild {
    width: 100%;
}

.manufature-category-wrapper {
    display: flex;
    gap: 10px;
}

.manufature-category-main {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
}

.manufacture-feilds {
    width: 100%;
}

.add-icon {
    background-color: #5B5B5B;
    padding: 13px 18px;
    border-radius: 8px;
    color: #fff;
    text-align: center;
}

/* manufacture status css  */
.manufacture-status-wrapper {
    margin-top: 18px;
}


.status p {
    color: #5B5B5B;
    font-size: 12px;
}

.registered-location-wrapper {
    margin-top: 18px;
}

.registerd-address {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 10px;
}

.address-feild {
    width: 100%;
}

.location-main {
    margin-top: 18px;
    display: flex;
    gap: 10px;
}

.location-info-main {
    width: calc(100% / 2);
}

.page-btn-wrapper {
    display: flex;
    gap: 22px;
    align-items: center;
    justify-content: right;
    margin-top: 18px;
    width: 100%;
}

.p-btn {
    font-size: 15px;
    font-weight: 600;
    text-transform: none;
    padding: 13px 26px !important;
    border-radius: 4px;
}

.cancle-btn {
    border: 1px solid #1D3679;
    color: #5B5B5B;
}

.next-btn {
    background-color: #1D3679 !important;
    color: #fff;

}


/* manufacturing location details css */
.manufaturing-location-details {
    margin-top: 30px;
    border: 1px solid grey;
    padding: 24px;
}

.add-location {
    display: inline-block;
    /* margin-top: 18px; */
}

.addcontact-wrapper {
    padding: 24px;
    border: 1px solid grey;
}

/* .main-wrapper {
    display: flex;
} */

/* categories component css  */
.options-main-wrapper {
    display: flex;
    gap: 15px;

}

.category-options {
    display: flex;
    margin-bottom: 18px;
    align-items: center;
    justify-content: space-between;
}

/* common pop up css  */

.common-pop-up {
    padding: 24px;
    border: 1px solid gray;
    margin: 20px 0px;
}

.blank-feild {
    width: 100%;
    margin-bottom: 18px;
}

.pop-inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 18px;
    align-items: center;
}

.pop-up-f {
    width: 50%;
}

.select-attribute-wrapper {
    margin-top: 24px;

}

.select-wrap {
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
}

.breadcrum {
    padding: 14px 12px;
    background-color: #EDEDED;
}

.pop-subheading {
    color: #4D4D4D;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.popup-heading {
    font-size: 22px;
    font-weight: 500;
    color: #4D4D4D;
}

.standard-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.managment-search {
    background: #D9D9D9;
    padding: 10px 20px;
}

/* preview page css  */
.preview-content {
    background: rgba(237, 237, 237, 0.29);
    padding: 24px;
    margin: 8px 0px;
}

.pre-subheading {
    color: #4D4D4D;
    font-size: 18px;
    font-weight: 600;
}
/* .pre-content {
    background-color: transparent;
} */
.pre-description p {
    color: #5B5B5B;
    font-size: 16px;
}
.preview-managment ul {
    display: flex;
    gap: 25px;
}
.specification-content {
    display: flex;
    gap: 80px;
    padding: 10px;
    align-items: center;
    /* border: 1px solid var(--input-border, #DDD); */

}
.pre-content {
    border-radius: 4px;
    /* border: 1px solid var(--input-border, #DDD); */
    background-color: transparent;
}
.preview-managment {
    background-color: rgba(237, 237, 237, 0.29);;
}
.border-main {
    border: 1px solid #DDD;

}
.spec-content-main {
    padding: 12px;
    border-radius: 4px;
}
.standard-managment {
    width: 50%;
}
.keywords ul {
display: flex;
gap: 18px;
margin-top: 20px;
}
.keywords ul li{
    border-radius: 100px;
    border: 1px solid #DDD;
    padding: 5px 15px;
}
.common-tabs {
    width: 25%;
}
.main-select {
    border: 1px solid #DDDDDD;
    padding: 12px;
    border-radius: 4px;
}
.pop-up-heading {
    font-size: 16px;
    color: #4D4D4D !important;
}
.categories-status {
    display: flex;
    gap: 30px;
}
.status-main {
    display: flex;
    flex-direction: column;
    gap: 10px;
}